@import "variabls";

.customizer-wrap {
  position: fixed;
  right: -232px;
  top: 100px;
  z-index: 1001;
  background-color: $white;
  width: 232px;
  transition: all 0.3s ease 0s;

  &.open {
    right: 0;
  }
}

.customizer-toggle {
  position: absolute;
  background-color: $white;
  color: $heading-color;
  left: -39px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid #eeeeee;

  & i {
    font-size: 14px;
    line-height: 40px;
  }
}

.customizer-inner {
  padding: 20px;
  border: 1px solid #eeeeee;
  position: relative;

  & h4 {
    color: $heading-color;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 10px;
  }
}

.color-setting {
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;

  & li {
    border: 1px solid transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: block;
    cursor: pointer;
    margin: 0 5px;

    &.active {
      border-color: #efefef;
    }
  }
}

.theme-setting {
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;

  & li {
    border: 1px solid #eeeeee;
    width: 30px;
    height: 30px;
    display: block;
    cursor: pointer;
    margin: 0 5px;
  }
}

.dark-version {
  & .customizer-wrap {
    background-color: $heading-color;
  }

  .customizer-toggle {
    background-color: $heading-color;
    color: $white;
    border: 1px solid lighten($heading-color, 10);
  }

  .customizer-inner {
    border: 1px solid lighten($heading-color, 10);

    & h4 {
      color: $white;
    }
  }

  .color-setting {
    li {
      &.active {
        border-color: #cccccc;
      }
    }
  }

  .theme-setting {
    & li {
      border: 1px solid lighten($heading-color, 10);
    }
  }
}

// Button Theme Switcher
.btn-theme {
  border: none;
  width: 100px;
  border-radius: 100px;
  line-height: 0;
  height: 40px;
  background-color: lighten(#cccccc, 10%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 10px;
  position: relative;

  &:after {
    content: 'Dark';
    margin-left: 28px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &:before {
    content: 'Light';
    margin-left: -30px;
    font-weight: 600;
    text-transform: uppercase;
    display: none;
  }

  .ball {
    height: 30px;
    width: 30px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 10px;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    transition: left 0.4s;
    pointer-events: none;

    &:after {
      content: "\f185";
      font-size: 20px;
      font-family: 'FontAwesome';
      position: absolute;
      top: 50%;
      transform: translate(-50%);
      left: 50%;
      pointer-events: none;
    }
  }

  &.active {
    background-color: #444;

    &:after {
      display: none;
    }

    &:before {
      display: inline-block;
    }


    .ball {
      background-color: #292929;
      left: 64px;

      &:after {
        content: '\f186';
        font-size: 16px;
      }
    }
  }
}